<template>
  <div class="view-container">
    <div class="view">
      <div class="view-body">
        <static-table
            :data="model"
            :options="options"
            :params="params"
            :thead="thead"
            @sortChange="sortChange"
        ></static-table>
        <el-card
            class="pagination-container mt-3"
            shadow="never"
        >
          <el-pagination
              :current-page="params.page"
              :page-size="params.per_page"
              :page-sizes="[10, 30, 50, 100, 300]"
              :total="total"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
              background
              layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </el-card>
        <!--<custom-table :source="source" :thead="thead" :options="options"></custom-table>-->
      </div>
    </div>
    <!--<div class="view">-->
    <!--<div class="view-heading">-->
    <!--<div class="view-title">-->
    <!--{{ $t('system.check_payment') }} - mBank (plik csv)-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="view-body">-->
    <!--<el-upload-->
    <!--class="upload-demo upload-width-auto"-->
    <!--drag-->
    <!--action="http://aberit.pm/invoice/check_payment">-->
    <!--<i class="el-icon-upload"></i>-->
    <!--<div class="el-upload__text">{{ $t('system.check_payment') }} - mBank (plik csv)</div>-->
    <!--</el-upload>-->
    <!--</div>-->
    <!--</div>-->
  </div>
</template>
<script>
import Vue from 'vue';
import axios from 'axios';
import Global from '../../helpers/global';
// import CustomTable from '../../components/CustomTable/index.vue'
import StaticTable from '../../components/ABtable/StaticTable.vue';
import notification from '../../notification/notify';

export default {
  name: 'InvoiceIndex',
  components: {
    // 'custom-table': CustomTable,
    StaticTable,
  },
  data() {
    return {
      title: 'Invoices',
      resource: 'invoice',
      source: 'invoice',
      create: '/invoice/create',
      thead: [
        {
          label: 'invoice.nrr',
          key: 'NrR',
          sort: true,
          link: true,
          url: '/invoice/',
        },
        // {
        //   label: 'invoice.alias',
        //   key: 'Alias',
        //   sort: true
        // },
        {
          label: 'invoice.wart',
          key: 'Wart',
          sort: true,
          filter: 'cash',
        },
        {
          label: 'invoice.payment_status',
          key: 'payment_status',
          sort: false,
          type: 'select',
        },
        {
          label: 'invoice.dpl',
          key: 'Dpl',
          sort: false,
        },
      ],
      options: {
        paginate: true,
        default_sort: 'NrR',
      },
      params: {
        current_page: '',
        from: '',
        to: '',
        last_page: '',
        column: 'NrR',
        direction: 'desc',
        per_page: 10,
        page: 1,
      },
      model: [],
      total: 0,
      newList: [],
    };
  },
  beforeMount() {
    this.fetchData();
  },
  methods: {
    check_payment() {
      let vm = this;
      let formData = new FormData();
      let files = this.$refs.issueDropzone.getAcceptedFiles();
      for (let i = 0; i < files.length; i++) {
        formData.append('file' + i, files[i]);
      }
      formData.append('filesCount', files.length);
      axios['post'](`invoice/check_payment`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then(function (response) {
            if (response.data.saved) {
              notification.notify(
                  vm.$t('notify.success'),
                  response.data.message,
                  'success');
              vm.refresh = true;
              vm.$refs.issueDropzone.removeAllFiles();
            }
          })
          .catch(function (error) {
            let dataErrors = error.response.data.errors;
            let errors = Global.getErrors(vm, dataErrors, 3);

            vm.$store.commit('SET_STICKY_ARRAY', {
              array: errors,
            });
            Vue.set(vm.$data, 'backend_errors', dataErrors);
          });
    },
    async fetchData() {
      var vm = this;
      vm.url = this.buildURL();
      await axios.get(vm.url)
          .then(function (response) {
            vm.model = response.data.data;
            if (typeof response.data.total !== 'undefined') {
              vm.total = response.data.total;
            } else {
              if (vm.options.paginate) {
                vm.params.current_page = parseInt(response.data.meta.current_page);
                vm.params.from = parseInt(response.data.meta.from);
                vm.params.last_page = parseInt(response.data.meta.last_page);
                vm.params.per_page = parseInt(response.data.meta.per_page);
                vm.params.to = parseInt(response.data.meta.to);
                vm.total = parseInt(response.data.meta.total);
              }
            }
            vm.newList = vm.model.slice();
          })
          .catch(function () {
          });
    },
    buildURL() {
      const p = this.params;
      if (this.options.paginate) {
        return `${this.source}?column=${p.column}&direction=${p.direction}&per_page=${p.per_page}&page=${p.page}`;
      } else {
        return `${this.source}?column=${p.column}&direction=${p.direction}`;
      }
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.fetchData();
    },
    handleSizeChange(val) {
      this.params.per_page = val;
      this.fetchData();
    },
    next() {
      if (this.model.next_page_url) {
        this.params.page++;
        this.fetchData();
      }
    },
    prev() {
      if (this.model.prev_page_url) {
        this.params.page--;
        this.fetchData();
      }
    },
    sortChange(column) {
      if (column === this.params.column) {
        if (this.params.direction === 'desc') {
          this.params.direction = 'asc';
        } else {
          this.params.direction = 'desc';
        }
      } else {
        this.params.column = column;
        this.params.direction = 'asc';
      }
      this.fetchData();
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
>
.upload-width-auto {
  .el-upload {
    width: 100%;

    .el-upload-dragger {
      width: 100%;
    }
  }
}
</style>
